import { ShepherdOptionsWithType } from 'react-shepherd';
import { MY_DOMAIN, simulateMouseClick } from '../utils/utils';

export function firstConnectionSteps(navigate: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = 'Prochain tutoriel';
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Découverte du site</span>`;

  const defaultDelay: number = 100;

  const stepsLength = 7;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: 'first-connection-1st',
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-next',
          text: "C'est parti pour la visite !",
          action() {
            this.next();
          },
        },
      ],
      classes: 'winylo-onboarding winylo-onboarding-categories-1st',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
        label: 'fg',
      },
      title: 'Bienvenue !',
      text: [
        `Je vous propose de faire un petit tour du dashboard<br/>
        <br/>
        pour bien comprendre les différents espaces.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, -100] } }],
      // },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'first-connection-2nd',
      attachTo: { element: '.onboarding-firstconnection-2nd', on: 'left-end' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: 'winylo-onboarding winylo-onboarding-categories-2nd',
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
        Tout d'abord, voici la barre de navigation. Ces pages sont regroupées en quatre catégories.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'first-connection-3rd',
      attachTo: { element: '.onboarding-firstconnection-3rd', on: 'left-end' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: 'winylo-onboarding winylo-onboarding-categories-2nd',
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
         <span class="important-text">Applications</span> : Découvrez notre bibliothèque d’applications.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'first-connection-4th',
      attachTo: { element: '.onboarding-firstconnection-4th', on: 'left-end' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: 'winylo-onboarding winylo-onboarding-categories-2nd',
      highlightClass: 'onboarding-firstconnection-highlight-4th',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
         <span class="important-text">Entreprise</span> : Créez et personnalisez le profil de votre entreprise.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'first-connection-5th',
      attachTo: { element: '.onboarding-firstconnection-5th', on: 'left-end' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: 'winylo-onboarding winylo-onboarding-categories-2nd',
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
         <span class="important-text">Utilisateurs</span> : Gérez vos utilisateurs ainsi que leurs rôles.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'first-connection-6th',
      attachTo: { element: '.onboarding-firstconnection-6th', on: 'left-end' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: 'winylo-onboarding winylo-onboarding-categories-2nd',
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        <br/>
         <span class="important-text">Espace de stockage</span> : Gardez un oeil le stockage de vos applications`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'first-connection-7th',
      attachTo: { element: '.onboarding-firstconnection-7th', on: 'bottom' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: 'winylo-onboarding winylo-onboarding-categories-2nd',
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
        <br/>
        Profitez  de nos <span class="important-text">tutoriels participatifs</span> <img src="${MY_DOMAIN}/onboarding/question-solid.svg" width="14px" height="14px" /><br/>
        pour vous aider à prendre en main la platforme<br/>
        le plus rapidement possible.<br/>
        Chaque tutoriel ne prends que quelques minutes.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'first-connection-8th',
      attachTo: { element: '.onboarding-firstconnection-8th', on: 'bottom' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: 'winylo-onboarding winylo-onboarding-categories-2nd',
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">7 sur ${stepsLength}</span><br/>
        <br/>
        Modifiez votre mot de passe depuis l’<span class="important-text">espace utilisateur</span> <img src="${MY_DOMAIN}/onboarding/user-solid.svg" width="14px" height="14px" />.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'first-connection-ending',
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-nexttutorial',
          text: nextTutoText,
          action() {
            navigate('/?onBoardingStart=1');
            this.complete();
          },
        },
      ],
      classes: 'winylo-onboarding',
      highlightClass: 'highlight',
      scrollTo: false,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">${title}</span> !<br/><br/>
      Vous pouvez vous arrêter là ou bien continuer en lançant<br/>
      le tutoriel <span class="important-text">"Découvrir, tester et souscrire à une application"</span>.`,
      ],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
