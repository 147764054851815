import { Link, useNavigate, useLocation } from 'react-router-dom';

import style from './SidePannel.module.css';
import { UserContextType, useUser } from '../../utils/context/User';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThLarge,
  faChartPie,
  faUser,
  faFileInvoice,
  faClipboardList,
  faCreditCard,
  faKey,
  IconDefinition,
  faHdd,
  faUsers,
  faHome,
  faAddressBook,
  faFlask,
  faLock,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ROLE } from '../../utils/api/_type';
import { useMutation, useQuery } from 'react-query';
import api from '../../utils/api';
import Utils from '../Utils';
import BugsModal from '../BugsModal/BugsModal';
import SessionLoadingModal from '../SessionLoadingModal/SessionLoadingModal';

export default function SidePannelCompany({ children }: { children: React.ReactElement }) {
  const [user] = useUser() as UserContextType;
  const navigate = useNavigate();
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isBugOpen, setIsBugOpen] = useState(false);
  const [isSessionLoadingOpen, setIsSessionLoadingOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { data: usersWaitingValidationCount } = useQuery('waiting_validation_count', api.companies.getCompanyUsersWaitingValidationCount, {
    retryDelay: 300000,
    enabled: user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN) ? true : false,
  });

  function MenuRow({ path, icon, text, role }: { path: string; icon: IconDefinition; text: React.ReactNode; role?: ROLE }) {
    return role === undefined || user?.roles.includes(role) ? (
      <>
        {path.length > 0 ? (
          <Link
            to={path}
            className={classNames(
              {
                [style.selectedRow]: location.pathname === path,
              },
              style.menuRow
            )}
          >
            <span className={style.iconContainer}>
              <FontAwesomeIcon icon={icon} className={style.icon} />
            </span>
            <span className={style.menuRowText}>{text}</span>
          </Link>
        ) : (
          <div
            className={classNames(
              style.noLink,
              {
                [style.selectedRow]: location.pathname === path,
              },
              style.menuRow
            )}
          >
            <span className={style.iconContainer}>
              <FontAwesomeIcon icon={icon} className={style.icon} />
            </span>
            <span className={style.menuRowText}>{text}</span>
          </div>
        )}
      </>
    ) : null;
  }

  function createPortalSession() {
    setIsSessionLoadingOpen(true);
    api.payment
      .createPortalSession({})
      .then((url) => {
        window.location.href = url;
      })
      .finally(() => {
        setIsSessionLoadingOpen(false);
      });
  }

  return (
    <div className={style.widthContainer}>
      <div className={classNames(style.left, { [style.opened]: isMenuOpen }, 'onboarding-firstconnection-2nd')}>
        {isMenuOpen ? (
          <img
            src={process.env.PUBLIC_URL + '/img/burger-close.svg'}
            alt="fermer"
            className={style.openCloseIcon}
            onClick={() => setIsMenuOpen((before) => !before)}
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + '/img/burger-open.svg'}
            alt="ouvrir"
            className={style.openCloseIcon}
            onClick={() => setIsMenuOpen((before) => !before)}
          />
        )}
        <div className={style.categoriesContainer}>
          <div className={style.category}>
            <MenuRow path="/" icon={faHome} text="Accueil" />
          </div>

          <div className={classNames(style.category, 'onboarding-firstconnection-3rd')}>
            <p className={classNames(style.title, style.firstTitle)}>Applications</p>
            <div className="onboarding-discovercategories-1st">
              <MenuRow path="/apps" icon={faThLarge} text="Applications" />
            </div>
          </div>

          <div className={style.overlayContainer}>
            {user === undefined ||
              (user?.company !== null && (user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN) || user?.roles.includes(ROLE.ROLE_COMPANY_ACCOUNTANT)) ? (
                <div className={classNames(style.category, 'onboarding-firstconnection-4th')}>
                  <p className={style.title}>Entreprise {user === undefined && <FontAwesomeIcon icon={faLock} color={'white'} />}</p>
                  {user !== undefined && (
                    <>
                      {user?.company !== null && user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN) ? (
                        <MenuRow path="/company/edit" icon={faUser} text="Profil d'entreprise" />
                      ) : null}

                      {user?.company !== null &&
                      (user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN) || user?.roles.includes(ROLE.ROLE_COMPANY_ACCOUNTANT)) ? (
                        <div className={style.bug} onClick={() => createPortalSession()}>
                          <MenuRow path="" icon={faClipboardList} text="Mes abonnements" />
                        </div>
                      ) : null}
                      {user?.company !== null && user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN) ? (
                        <MenuRow path="/company/referal" icon={faAddressBook} text="Parrainage" />
                      ) : null}
                      {user?.company !== null && user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN) ? (
                        <MenuRow path="/company/trial-request" icon={faFlask} text="Demandes de tests" />
                      ) : null}
                    </>
                  )}
                </div>
              ) : null)}

            {user === undefined || (user?.company !== null && user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN)) ? (
              <div className={classNames(style.category, 'onboarding-firstconnection-5th')}>
                <p className={style.title}>Utilisateurs {user === undefined && <FontAwesomeIcon icon={faLock} color={'white'} />}</p>
                {user !== undefined && (
                  <>
                    <div className={classNames('onboarding-createuser-1st', 'onboarding-edituser-1st')}>
                      <MenuRow
                        path="/company/users"
                        icon={faUsers}
                        text={
                          <>
                            Gestion des utilisateurs{' '}
                            {usersWaitingValidationCount && usersWaitingValidationCount.count > 0 && (
                              <span className={style.badge}>
                                <span className={style.badgeContent}>
                                  {usersWaitingValidationCount.count > 9 ? 9 : usersWaitingValidationCount.count}
                                </span>
                              </span>
                            )}
                          </>
                        }
                      />
                    </div>
                    <MenuRow path="/company/users/stats" icon={faChartPie} text="Statistiques" />
                  </>
                )}
              </div>
            ) : null}

            {user === undefined || (user?.company !== null && user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN)) ? (
              <>
                <div className={classNames(style.category, 'onboarding-firstconnection-6th')}>
                  <p className={style.title}>Espace de stockage {user === undefined && <FontAwesomeIcon icon={faLock} color={'white'} />}</p>
                  {user !== undefined && (
                    <div className="onboarding-storagemanagement-1st">
                      <MenuRow path="/company/storage" icon={faHdd} text="Gestion du stockage" />
                    </div>
                  )}
                </div>
                {!user && (
                  <>
                    <span className={style.textLockedContainer}>
                      <FontAwesomeIcon icon={faLock} color={'white'} />
                      <p className={style.textLocked}>Vous devez être connecté</p>
                    </span>

                    <Utils.Button format="square" variant="secondary" style={{ marginRight: '15px' }} onClick={() => navigate('/register')}>
                      S'inscrire
                    </Utils.Button>
                    <Utils.Button format="square" variant="primary" style={{ marginRight: '15px' }} onClick={() => navigate('/login')}>
                      Se connecter
                    </Utils.Button>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className={style.bug} onClick={() => setIsBugOpen(true)}>
          <MenuRow path="" icon={faBullhorn} text="Remonter un bug" />
        </div>
      </div>
      <div className={classNames(style.right, { [style.opened]: isMenuOpen })}>{children}</div>

      <BugsModal isOpen={isBugOpen} onClose={() => setIsBugOpen(false)} />

      <SessionLoadingModal isOpen={isSessionLoadingOpen} onClose={() => setIsSessionLoadingOpen(false)} />
    </div>
  );
}
