import { ShepherdOptionsWithType } from 'react-shepherd';
import { MY_DOMAIN, simulateMouseClick } from '../utils/utils';

export function editUserSteps(navigate: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = 'Prochain tutoriel';
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Gestion des droits</span>`;

  const classes: string = 'winylo-onboarding onboarding-edituser';

  const defaultDelay: number = 500;

  const stepsLength = 6;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: 'edit-user-1st',
      attachTo: { element: '.onboarding-edituser-1st', on: 'right' },
      advanceOn: { selector: '.onboarding-edituser-1st', event: 'click' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          disabled: true,
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            navigate('/company/users');
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur l\'onglet <span class="important-text">Gestion des utilisateurs</span> dans le menu à gauche.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'edit-user-2nd',
      attachTo: { element: '.onboarding-edituser-2nd', on: 'top' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            navigate('/');
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
          Ici se trouve la <span class="important-text">liste des utilisateurs</span> de votre compagnie.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'edit-user-3rd',
      attachTo: { element: '.onboarding-edituser-3rd', on: 'left' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
        Vous pouvez choisir si un compte est <span class="important-text">actif</span>, c'est à dire si il est possible de se connecter avec celui-ci.
        <br/>
        Vous avez aussi la possibilité de définir si le compte possède les accès <span class="important-text">Administrateur</span> sur le site principal.
        `,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'edit-user-4th',
      attachTo: { element: '.onboarding-edituser-4th', on: 'left' },
      advanceOn: { selector: '.onboarding-edituser-4th', event: 'click' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            var element = document.getElementById('onboarding-edituser-next-4th');
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur le bouton <span class="important-text">Paramètres</span>.
        `,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'edit-user-5th',
      attachTo: { element: '.onboarding-edituser-5th', on: 'right' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            var element = document.getElementById('close-modal-icon');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        <br/>
        Ici, vous pouvez modifier les propriétés de l'utilisateur.
        <br/>
        Laissez le champ <span class="important-text">mot de passe</span> vide pour ne pas le modifier.
        `,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'edit-user-6th',
      attachTo: { element: '.onboarding-edituser-6th', on: 'left' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            var element = document.getElementById('close-modal-icon');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
        <br/>
        Enfin, ici vous pouvez cocher les différents droits dont dispose l'utilisateur.
        <br/>
        Pour valider vos modifications, vous devez cliquez sur le bouton <span class="important-text">Modifier</span>
        `,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'edit-user-7th',
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-nexttutorial',
          text: nextTutoText,
          action() {
            navigate('/?onBoardingStart=4');
            this.complete();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">${title}</span> !<br/><br/>
        Vous pouvez vous arrêter là ou bien continuer<br/>
        en lançant le tutoriel <span class="important-text">"Gestion du stockage"</span>.`,
      ],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
