import { ShepherdOptionsWithType } from 'react-shepherd';
import { MY_DOMAIN, simulateMouseClick } from '../utils/utils';

export function createUserSteps(navigate: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = 'Prochain tutoriel';
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Ajouter un utilisateur</span>`;

  const classes: string = 'winylo-onboarding onboarding-createuser';

  const defaultDelay: number = 500;

  const stepsLength = 5;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: 'create-user-1st',
      attachTo: { element: '.onboarding-createuser-1st', on: 'right' },
      advanceOn: { selector: '.onboarding-createuser-1st', event: 'click' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          disabled: true,
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            navigate('/company/users');
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur l\'onglet <span class="important-text">Gestions des utilisateurs</span> dans le menu à gauche.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'create-user-2nd',
      attachTo: { element: '.onboarding-createuser-2nd', on: 'top' },
      advanceOn: { selector: '.onboarding-createuser-2nd', event: 'click' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            navigate('/');
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            var element = document.getElementById('onboarding-createuser-next-2nd');
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur le bouton <span class="important-text">Créer des utilisateurs</span> en haut à droite.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'create-user-3rd',
      attachTo: { element: '.onboarding-createuser-3rd', on: 'top' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            var element = document.getElementById('close-modal-icon');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
        Ici vous pouvez partager un <span class="important-text">lien d'inscription</span> à envoyer, ce qui permettra aux destinataires de créer un compte dans votre entreprise.
        <br/>
        Vous êtes en mesure de <span class="important-text">regénérer le lien</span>, ce qui désactivera le précédent.
      `,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'create-user-4th',
      attachTo: { element: '.onboarding-createuser-4th', on: 'top' },
      advanceOn: { selector: '.onboarding-createuser-next-4th', event: 'click' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            var element = document.getElementById('onboarding-createuser-next-4th');
            simulateMouseClick(element);
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
        Enfin, il vous est possible de le partager directement <span class="important-text">par mail</span> depuis cette fenêtre en remplissant le formulaire.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'create-user-5th',
      attachTo: { element: '.onboarding-createuser-5th', on: 'top' },
      advanceOn: { selector: '.onboarding-createuser-next-5th', event: 'click' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            var element = document.getElementById('onboarding-createuser-previous-5th');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            var element = document.getElementById('onboarding-createuser-next-5th');
            simulateMouseClick(element);
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
      Sur cet onglet, vous pouvez créer un utilisateur en remplissant le formulaire et en cochant les permissions que vous voulez lui donner.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'create-user-6th',
      attachTo: { element: '.onboarding-createuser-6th', on: 'top' },
      advanceOn: { selector: '.onboarding-createuser-next-6th', event: 'click' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            var element = document.getElementById('onboarding-createuser-previous-6th');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        <br/>
        Dernièrement, le troisième onglet vous permet d'importer un fichier CSV et de lier les colonnes aux propriétés nécéssaires à la création d'utilisateurs.`,
      ],
      // popperOptions: {
      //   modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      // },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'create-user-7th',
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-nexttutorial',
          text: nextTutoText,
          action() {
            navigate('/?onBoardingStart=3');
            this.complete();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">${title}</span> !<br/>
         Vous pouvez vous arrêter là ou bien continuer en lançant le tutoriel <span class="important-text">"Modifier un utilisateur"</span>.<br/>
      `,
      ],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
