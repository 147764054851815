import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './index.css';
import './form.css';
import ContextProvider from './utils/context/ContextProvider';
import ErrorCatcher from './components/ErrorCatcher';
import { STRIPE_KEY } from './utils/stripe';

import './react_components/css/index.css';
import { createRoot } from 'react-dom/client';

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Create Stripe
const stripePromise = loadStripe(STRIPE_KEY);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorCatcher>
      <Elements stripe={stripePromise}>
        <ContextProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ContextProvider>
      </Elements>
    </ErrorCatcher>
  </React.StrictMode>
);
